const BASE_URL = process.env.NODE_ENV === 'production' ? "https://api.hrms.acumengroup.in" : "https://devhrms.acumengroup.in"

export const URLS = {
    LOGIN: `${BASE_URL}/v1/login`,
    AUTHENTICATE_TOKEN: `${BASE_URL}/v1/token`,
    FILE_URL: `${BASE_URL}/v1/file`,
    EMPLOYEE: `${BASE_URL}/v1/employee`,
    EMPLOYEES_UPLOAD: `${BASE_URL}/v1/employees/upload`,
    EMPLOYEE_DETAILS: `${BASE_URL}/v1/employee/{id}`,
    EMPLOYEES: `${BASE_URL}/v1/employees`,
    EMPLOYEES_MINIMAL: `${BASE_URL}/v1/employees/minimal`,
    REPORTING_PERSON_MINIMAL: `${BASE_URL}/v1/reporting/person/minimal`,
    REPORTEES_GOALS: `${BASE_URL}/v1/goals/reportees`,
    DESIGNATIONS_LIST: `${BASE_URL}/v1/designations`,
    DESIGNATIONS_LIST_MINIMAL: `${BASE_URL}/v1/designations/minimal`,
    DESIGNATION: `${BASE_URL}/v1/designation`,
    DESIGNATION_ID: `${BASE_URL}/v1/designation/{id}`,
    DESIGNATION_STATUS_TOGGLE: `${BASE_URL}/v1/designation/status`,
    DEPARTMENTS_LIST: `${BASE_URL}/v1/departments`,
    DEPARTMENTS_LIST_MINIMAL: `${BASE_URL}/v1/departments/minimal`,
    DEPARTMENT: `${BASE_URL}/v1/department`,
    DEPARTMENT_ID: `${BASE_URL}/v1/department/{id}`,
    DEPARTMENT_STATUS_TOGGLE: `${BASE_URL}/v1/department/status`,
    LOCATIONS_LIST: `${BASE_URL}/v1/locations`,
    LOCATIONS_LIST_MINIMAL: `${BASE_URL}/v1/locations/minimal`,
    LOCATION: `${BASE_URL}/v1/location`,
    LOCATION_ID: `${BASE_URL}/v1/location/{id}`,
    LOCATION_STATUS_TOGGLE: `${BASE_URL}/v1/location/status`,
    STATES_LIST: `${BASE_URL}/v1/states`,
    STATE: `${BASE_URL}/v1/state`,
    STATE_ID: `${BASE_URL}/v1/state/{id}`,
    STATES_LIST_MINIMAL: `${BASE_URL}/v1/states/minimal`,
    STATE_STATUS_TOGGLE: `${BASE_URL}/v1/state/status`,
    ROLES_LIST: `${BASE_URL}/v1/roles`,
    ROLE: `${BASE_URL}/v1/role`,
    ROLE_ID: `${BASE_URL}/v1/role/{type}/{id}`,
    SAMPLES_IMPORT: `${BASE_URL}/v1/samples/import`,
    GOALS_UPLOAD: `${BASE_URL}/v1/goals/upload`,
    GOALS: `${BASE_URL}/v1/goals`,
    GOAL: `${BASE_URL}/v1/goal`,
    GOAL_ID: `${BASE_URL}/v1/goal/{id}`,
    ALL_GOALS: `${BASE_URL}/v1/goals/all`,
    REPORTEES: `${BASE_URL}/v1/reportees`,
    REPORTEES_MINIMAL: `${BASE_URL}/v1/reportees/minimal`,
    // REPORTEES_GOALS_MINIMAL: `${BASE_URL}/v1/goals/minimal/reportees`,
    ALL_REVIEWS: `${BASE_URL}/v1/reviews/all`,
    REVIEWS: `${BASE_URL}/v1/reviews`,
    REVIEWS_REPORTEES: `${BASE_URL}/v1/reviews/reportees`,
    REVIEWS_PENDING: `${BASE_URL}/v1/reviews/pending/{level}`,
    PERFORM_REVIEW: `${BASE_URL}/v1/review/level/{level}`,
    MY_REPORTEES_PENDING_REVIEWS: `${BASE_URL}/v1/employees/review/{level}`,
    REVIEW_ID: `${BASE_URL}/v1/review/{id}`,
    REVIEWS_PENDING_BY_REPORTEES: `${BASE_URL}/v1/reportees/review/pending`,
    REVIEWS_PENDING_BY_EMPLOYEES: `${BASE_URL}/v1/employees/review/pending/{level}`,
    AUDITS: `${BASE_URL}/v1/audits`,
    JOBS: `${BASE_URL}/v1/jobs`,
    MASTER_JOBS: `${BASE_URL}/v1/master/jobs`,
    JOBS_LATEST_STATUS: `${BASE_URL}/v1/jobs/latest/status`,
    FINANCIAL_YEAR: `${BASE_URL}/v1/financial/years`,
    EMPLOYEES_REVIEW_REPORT_FOR_FY: `${BASE_URL}/v1/employees/report/{year}`,
    EMPLOYEES_REVIEW_MONTHLY_REPORT_FOR_FY: `${BASE_URL}/v1/employees/monthly/report/{year}`,
    REPORTEES_REVIEW_REPORT_FOR_FY: `${BASE_URL}/v1/reportees/report/{year}`,
    REPORTEES_REVIEW_MONTHLY_REPORT_FOR_FY: `${BASE_URL}/v1/reportees/monthly/report/{year}`,
    EMPLOYEE_REVIEW_REPORT_FOR_FY: `${BASE_URL}/v1/report/{year}/{id}`,
    EMPLOYEE_REVIEW_MONTHLY_REPORT_FOR_FY: `${BASE_URL}/v1/monthly/report/{year}/{id}`,
    MY_REVIEW_REPORT_FOR_FY: `${BASE_URL}/v1/report/{year}`,
    MY_REVIEW_MONTHLY_REPORT_FOR_FY: `${BASE_URL}/v1/monthly/report/{year}`,
}